<template>
  <BUTTON :button-state="buttonState" :data="data"></BUTTON>
</template>

<script>
import BUTTON from './BUTTON';

export default {
  name: 'ANZBUTTON',

  components: {
    BUTTON
  },

  props: {
    buttonState: {
      type: Object,
      required: false,
      default: () => {
        return {
          disabled: false
        };
      }
    },

    data: {
      type: Object,
      required: true
    }
  }
};
</script>
